import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabLink']

  switchTab(event) {
    this.tabTargets.forEach(tab => tab.classList.remove('active'))
    this.tabLinkTargets.forEach(tabLink => tabLink.classList.remove('active'))

    const tabName = event.currentTarget.dataset.content
    const newTab = this.tabTargets.find(tab => tab.dataset.name === tabName)

    newTab.classList.add('active')
    event.currentTarget.classList.add("active")
  }
}
