import {Controller} from 'stimulus'
import AutoComplete from '@tarekraafat/autocomplete.js'
import {AutoCompleteUtils} from "../utils";
import {Turbo} from '@hotwired/turbo-rails'

export default class extends Controller {
  static values = {
    url: String,
    redirectUrl: String
  }

  connect() {
    new AutoComplete({
      data: {
        src: async () => {
          const query = this.element.value;
          const source = await fetch(`${this.urlValue}?term=${encodeURIComponent(query)}`);
          return await source.json();
        },
        key: ['title']
      },
      selector: () => {
        return this.element;
      },
      highlight: true,
      placeHolder: I18n.t('parent_select.placeholder'),
      resultsList: {
        container: source => {
          this.optionsContainer = source;
          AutoCompleteUtils.createAbsoluteDropdown(source, this.element)
        },
        destination: () => {
          return document.body;
        },
        className: 'collection autocomplete',
        element: 'div'
      },
      resultItem: {
        content:  AutoCompleteUtils.createResultItem,
        element: "a",
        className: 'collection-item'
      },
      onSelection: feedback => {
        Turbo.visit(`${this.redirectUrlValue}?parent_id=${feedback.selection.value.id}`)
      }
    })

    this.element.addEventListener('keydown', AutoCompleteUtils.bindDefaultKeyEvents)
  }
}
