import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tagCheckbox']

  checkCategoryBoxes(event){
    const target = event.currentTarget;
    this.tagCheckboxTargets.forEach(checkbox => {
      if (target.checked) {
        checkbox.checked = true
        checkbox.classList.add('checked-for-tag');
      } else {
        checkbox.checked = false
        checkbox.classList.remove('checked-for-tag');
      }
    });
    const categoryChange = new Event('category-change');
    this.element.dispatchEvent(categoryChange);
  }

}
