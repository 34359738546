import {Controller} from 'stimulus'

export default class extends Controller {
  static values = {
    productId: String
  }

  disconnect(){
    const rowClass = this.element.closest('tr').classList[0];
    const productCard = document.querySelector(`#product_${this.productIdValue}`);
    if(productCard == null) return;

    const productTableBody = productCard.querySelector('tbody');
    document.querySelectorAll(`.${rowClass}`).forEach(row => {
      row.remove();
    });
    if(productTableBody.querySelectorAll('tr').length === 0){
      productCard.remove();
    }
  }
}
