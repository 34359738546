import {Controller} from 'stimulus'

export default class extends Controller{
  static values = {
    options: Array
  }

  connect(){
    const autocompleteData = {}
    this.optionsValue.forEach(option => {
      autocompleteData[option] = null;
    })
    this.autocompleteInstance = M.Autocomplete.init(this.element, {
      data: autocompleteData
    })
  }

  disconnect(){
    if(this.autocompleteInstance != null) this.autocompleteInstance.destroy();
  }
}
