import {Controller} from 'stimulus'
import M from "materialize-css";

export default class extends Controller {
  static values = {
    tags: Array,
    autocomplete: Array,
    placeholder: String
  }

  static targets = ['submitButton', 'tagsField', 'hiddenTagsField']

  connect() {
    let current_tags_hash = '';
    if (this.hasTagsValue) {
      current_tags_hash = this.tagsValue.map(x => {
        return ({
          tag: x
        })
      })
    }
    let autocomplete_data = {};
    if (this.hasAutocompleteValue) {
      this.autocompleteValue.forEach(x => {
        autocomplete_data[x] = null
      })
    }
    this.tagsFieldTarget.querySelectorAll('.chip').forEach( chip => { chip.remove() });
    M.Chips.init(this.tagsFieldTarget, {
      placeholder: this.hasPlaceholderValue ? this.placeHolderValue : '',
      data: current_tags_hash,
      autocompleteOptions: {
        data: autocomplete_data,
        limit: Infinity,
        minLength: 1
      },
      onChipAdd: () => {
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.disabled = false;
        }
      },
      onChipDelete: () => {
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.disabled = false;
        }
      },
    })
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true;
    }

    let formWrapper = this.tagsFieldTarget.closest('form');
    if (formWrapper != null) {
      formWrapper.addEventListener('submit', () => {
        const instance = M.Chips.getInstance(this.tagsFieldTarget)
        this.hiddenTagsFieldTarget.value = instance.chipsData.map( x => {
          return x['tag']
        }).join(', ');
      });
    }
  }

  disconnect() {
    // The autocomplete does not get destroyed on its own.
    // Make sure it is removed so that tags index does not break on back button.
    const chips = M.Chips.getInstance(this.tagsFieldTarget)
    if (!chips) return

    chips.autocomplete.destroy()
    chips.destroy()
  }
}
