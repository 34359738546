import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['overridableCheckbox', 'defaultMainCheckbox']

  connect(){
    this.toggleDefaultMainRow();
  }

  toggleDefaultMainRow(){
    let defaultMainDiv = this.defaultMainCheckboxTarget.closest('.row');
    if (this.overridableCheckboxTarget.checked) {
      defaultMainDiv.style.display = 'block';
      this.defaultMainCheckboxTarget.disabled = false;
    } else {
      defaultMainDiv.style.display = 'none'
      this.defaultMainCheckboxTarget.disabled = true;
    }
  }
}
