import {Controller} from 'stimulus'

export default class extends Controller {
  uploadInitialize(event){
    const { target, detail } = event
    const { id, file } = detail
    target.closest('.file-field').insertAdjacentHTML("beforeend", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
    document.querySelector(`#direct-upload-${id}`).querySelector(`.direct-upload__filename`).textContent = file.name
  }

  uploadStart(event){
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  }

  uploadProgress(event){
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  }

  uploadError(event){
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  }

  uploadEnd(event){
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
  }
}
