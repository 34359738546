export function getOffsetLeft(elem) {
  let offsetLeft = 0;
  do {
    if (!isNaN(elem.offsetLeft)) {
      offsetLeft += elem.offsetLeft;
    }
  } while ((elem = elem.offsetParent) != null);
  return offsetLeft;
}

export function getOffsetTop(elem) {
  let offsetTop = 0;
  do {
    if (!isNaN(elem.offsetTop)) {
      offsetTop += elem.offsetTop;
    }
  } while ((elem = elem.offsetParent) != null);
  return offsetTop;
}

export class AutoCompleteUtils {
  static createAbsoluteDropdown(source, inputField) {
    source.style.position = 'absolute';
    source.style.top = `${getOffsetTop(inputField) + inputField.getBoundingClientRect().height}px`;
    source.style.left = `${getOffsetLeft(inputField)}px`;
    source.style.width = `${inputField.getBoundingClientRect().width}px`;
    source.removeAttribute('id');
  }

  static createResultItem(data, source) {
    source.innerHTML = data.match;
    source.removeAttribute('id');
    source.href = '#!';
    source.dataset.turbo = 'false';
  }

  static bindDefaultKeyEvents(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}
