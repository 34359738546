import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['abraField', 'abraCheckbox']

  connect() {
    if(this.hasAbraCheckboxTarget) {
      this.toggleAbraFields();
    }
  }

  toggleAbraFields() {
    if (this.abraCheckboxTarget.checked) {
      this.abraFieldTargets.forEach(abraField => {
        abraField.style.display = 'none';
        abraField.querySelectorAll('input, select').forEach(elem => { elem.disabled = true });
      });
    } else {
      this.abraFieldTargets.forEach(abraField => {
        abraField.style.display = 'block';
        abraField.querySelectorAll('input, select').forEach(elem => { elem.disabled = false });
      });
    }
    this.abraFieldTargets.forEach(abraField => {
      const select = abraField.querySelector('select');
      const mattSelect = M.FormSelect.getInstance(select);
      if (mattSelect != null) {
        mattSelect.destroy();
      }
      M.FormSelect.init(select, {
        dropdownOptions: {
          container: document.querySelector('body')
        }
      })
    });
  }
}
