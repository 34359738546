import {Controller} from 'stimulus'
import Flash from '../flash'

export default class extends Controller {
  static values = {
    notice: String,
    error: String
  }

  connect(){
    if(this.hasNoticeValue){
      Flash.notice(this.noticeValue);
    }
    if(this.hasErrorValue){
      Flash.error(this.errorValue);
    }
  }
}
