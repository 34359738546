import {Controller} from 'stimulus'

/*
  Tooltips do not disappear when the tooltipped element is removed from the DOM.
  Add this controller to the relevant element and trigger the remove action when appropriate.
*/
export default class extends Controller {
  remove() {
    const tooltip = M.Tooltip.getInstance(this.element)
    if (tooltip) {
      tooltip.destroy()
    }
  }
}
