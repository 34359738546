import M from "materialize-css";

export function mattInit(container = document.body) {
  M.AutoInit(container)
  M.updateTextFields()
  M.Modal._count = 0 // Reset
  M.ScrollSpy._count = 0; // Reset

  // Materialize autoinit seems to sometimes override our inits with custom options
  // destroying any current instances before our init should fix this
  let elems = container.querySelectorAll('.timepicker');
  elems.forEach(elem => {
    const instance = M.Timepicker.getInstance(elem);
    if (instance != null) instance.destroy();
  })
  M.Timepicker.init(elems, {
    twelveHour: false,
    i18n: timePickerLocalization(I18n.locale)
  });

  elems = container.querySelectorAll('.dropdown-trigger');
  elems.forEach(elem => {
    const instance = M.Dropdown.getInstance(elem);
    if (instance != null) instance.destroy();
  })
  M.Dropdown.init(elems, {
    constrainWidth: false,
    coverTrigger: false
  });

  elems = container.querySelectorAll('select:not(.no-autoinit)');
  elems.forEach(elem => {
    const instance = M.FormSelect.getInstance(elem);
    if (instance != null) instance.destroy();
  })
  M.FormSelect.init(elems, {
    dropdownOptions: {
      container: document.body
    }
  })
  container.querySelectorAll('select[data-invalid="1"]').forEach(select => {
    const parent = select.closest(".select-wrapper");
    if (parent != null) {
      const matSelect = parent.querySelector("input.select-dropdown")
      if (matSelect != null) matSelect.classList.add("invalid");
    }
  })


  const dateTimePickers = container.querySelectorAll('.datetimepicker');
  dateTimePickers.forEach(dtPicker => {
    initDateTimePicker(dtPicker)
  });

  const datePickers = container.querySelectorAll('.datepicker');
  datePickers.forEach(dPicker => {
    initDatePicker(dPicker)
  })

  M.Sidenav._sidenavs = []
  const sideNavs = container.querySelectorAll('.sidenav');
  sideNavs.forEach(sideNav => {
    const instance = M.Sidenav.getInstance(sideNav);
    if (instance != null) instance.destroy();
  });
  M.Sidenav.init(sideNavs, {
    edge: 'left',
    draggable: true
  });

  const rightSideNavs = container.querySelectorAll('.sidenav-right');
  M.Sidenav.init(rightSideNavs, {
    edge: 'right'
  });

  // Resize textareas if they are prefilled
  const textAreas = container.querySelectorAll('.materialize-textarea');
  textAreas.forEach(area => {
    M.textareaAutoResize(area);
  });

  const tooltips = container.querySelectorAll('.tooltipped');
  tooltips.forEach(tooltip => {
    const instance = M.Tooltip.getInstance(tooltip);
    if (instance != null) instance.destroy();
  })
  M.Tooltip.init(tooltips, {
    delay: 50
  });

  const carousels = container.querySelectorAll('.carousel.carousel-slider');
  carousels.forEach(carousel => {
    const instance = M.Carousel.getInstance(carousel);
    if (instance != null) instance.destroy();

    const image = carousel.querySelector('img');
    if(image != null){
      const carouselOptions = {
        fullWidth: true,
        indicators: true,
        noWrap: true,
        numVisible: 1
      }

      if (image.complete) {
        M.Carousel.init(carousel, carouselOptions)
      } else {
        image.addEventListener('load', () => M.Carousel.init(carousel, carouselOptions))
      }
    }
  })

  const scrollspies = container.querySelectorAll('.scrollspy');
  scrollspies.forEach(scrollspy => {
    const instance = M.ScrollSpy.getInstance(scrollspy);
    if(instance != null) instance.destroy();
  })
  M.ScrollSpy.init(scrollspies, {
    scrollOffset: 74
  });
}

export function initDatePicker(dPicker) {
  let dateValue = null

  M.Datepicker.init(dPicker, {
    format: 'dd. mm. yyyy',
    autoClose: true,
    firstDay: 1,
    onDraw: datePickerHolidays,
    i18n: datePickerLocalization(I18n.locale),
    onSelect: function () {
      dateValue = this.toString()
    },
  })

  dPicker.addEventListener("change", (event) => {
    if (dateValue)
      event.target.value = dateValue
  });
}

export function initDateTimePicker(dtPicker) {
  let dateValue = null
  let timeValue = null

  M.Datepicker.init(dtPicker, {
    format: 'dd. mm. yyyy',
    autoClose: true,
    firstDay: 1,
    onDraw: datePickerHolidays,
    i18n: datePickerLocalization(I18n.locale),
    onSelect: function () {
      dateValue = this.toString()
    },
    onClose: function () {
      const timepicker = M.Timepicker.init(dtPicker, {
        twelveHour: false,
        autoClose: true,
        i18n: timePickerLocalization(I18n.locale),
        onSelect: value => timeValue = value,
        onCloseEnd: () => {
          timepicker.destroy()
          dtPicker.blur()
        }
      });
      timepicker.open();
    }
  });

  dtPicker.addEventListener("change", (event) => {
    if (dateValue && time)
      event.target.value = dateValue + " " + timeValue
  });
}

export function datePickerLocalization(locale) {
  switch (locale) {
    case 'cs':
      return {
        months: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
        monthsShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
        weekdays: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
        weekdaysShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
        weekdaysAbbrev: ['N', 'P', 'Ú', 'S', 'Č', 'P', 'S'],
        cancel: 'Zavřít',
        clear: 'Vymazat',
        done: 'Potvrdit'
      }
      break;
    default:
      return null
  }

}

export function timePickerLocalization(locale) {
  switch (locale) {
    case 'cs':
      return {
        cancel: 'Zavřít',
        clear: 'Vymazat',
        done: 'Potvrdit'
      }
      break;
    default:
      return {
        cancel: 'Cancel',
        clear: 'Clear',
        done: 'Ok'
      }
  }
}

export function datePickerHolidays(mattInst) {
  if (!mattInst.isOpen)
    return;

  const table = mattInst.calendarEl.querySelector('table');
  if (table != null) {
    const cols = table.querySelectorAll('td > button');
    cols.forEach(col => {
      const year = col.dataset.year;
      const month = parseInt(col.dataset.month) + 1;
      const day = col.dataset.day;
      if (Holidays[year] != null && Holidays[year][month] != null && Holidays[year][month].includes(day)) {
        col.parentElement.classList.add('is-holiday');
      }
    })
  }
}

export function mattDestroy(container){
  // Materialize autoinit seems to sometimes override our inits with custom options
  // destroying any current instances before our init should fix this
  let elems = container.querySelectorAll('.timepicker');
  elems.forEach(elem => {
    const instance = M.Timepicker.getInstance(elem);
    if (instance != null) instance.destroy();
  })

  elems = container.querySelectorAll('.dropdown-trigger');
  elems.forEach(elem => {
    const instance = M.Dropdown.getInstance(elem);
    if (instance != null) instance.destroy();
  })

  elems = container.querySelectorAll('select:not(.no-autoinit)');
  elems.forEach(elem => {
    const instance = M.FormSelect.getInstance(elem);
    if (instance != null) instance.destroy();
  })

  const sideNavs = container.querySelectorAll('.sidenav');
  sideNavs.forEach(sideNav => {
    const instance = M.Sidenav.getInstance(sideNav);
    if (instance != null) instance.destroy();
  });

  const tooltips = container.querySelectorAll('.tooltipped');
  tooltips.forEach(tooltip => {
    const instance = M.Tooltip.getInstance(tooltip);
    if (instance != null) instance.destroy();
  })

  const carousels = container.querySelectorAll('.carousel.carousel-slider');
  carousels.forEach(carousel => {
    const instance = M.Carousel.getInstance(carousel);
    if (instance != null) instance.destroy();
  })

  const scrollspies = container.querySelectorAll('.scrollspy');
  scrollspies.forEach(scrollspy => {
    const instance = M.ScrollSpy.getInstance(scrollspy);
    if(instance != null) instance.destroy();
  })
}
