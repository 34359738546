import {Controller} from 'stimulus'

/*
  Lazily loaded turbo frames do not reload when their src attribute changes.
  In order to make their reloading work properly, it is necessary to change
  their loading policy to eager once they are loaded for the frist time.

  Set the frame value to the id of the turbo frame
  and put the controller inside the html that will be rendered the first time.
*/
export default class extends Controller {
  static values = {
    frame: String
  }

  connect() {
    const frame = document.getElementById(this.frameValue)

    if (!frame) {
      console.error(`Could not find turbo-frame with id: ${this.frameValue}`)
    } else if (frame.loading === 'lazy') {
      frame.loading = 'eager'
    }
  }
}
