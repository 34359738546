import {Controller} from 'stimulus'
import Rails from '@rails/ujs'
import Flash from '../flash'
import {createLoadingCircle} from "../spinner";

export default class extends Controller {
  static targets = ['tagList', 'tagCheckbox', 'categoryCheckbox', 'spinner']

  static values = {
    submitUrl: String
  }

  connect() {
    this.tagsBeforeChange = [];
  }

  toggleCheckedTag(event) {
    const target = event.currentTarget;
    if (target.checked) {
      target.classList.add('checked-for-tag');
    } else {
      target.classList.remove('checked-for-tag');
    }
    this.reloadTags();
  }

  checkAllBoxes(event) {
    const target = event.currentTarget;
    this.tagCheckboxTargets.forEach(checkbox => {
      if (target.checked) {
        checkbox.checked = true
        checkbox.classList.add('checked-for-tag');
      } else {
        checkbox.checked = false
        checkbox.classList.remove('checked-for-tag');
      }
    });
    this.categoryCheckboxTargets.forEach(checkbox => {
      checkbox.checked = target.checked;
    })
    this.reloadTags();
  }

  reloadTags() {
    const checkedForTagIds = this.getIdsFromCheckedForTag();
    Rails.ajax({
      url: '/product_tags?ids=' + checkedForTagIds.join('-'),
      type: 'GET',
      error: () => {
        Flash.error('Obnovení tagů se nezdařilo');
      },
      success: (res) => {
        let tags = res.tags;
        if (tags == null) {
          this.tagsBeforeChange = [];
          tags = []
        } else {
          this.tagsBeforeChange = tags;
        }
        const instance = M.Chips.getInstance(this.tagListTarget)
        if (instance != null) {
          while (instance.chipsData.length !== 0) {
            instance.deleteChip(0);
          }
          tags.forEach((item) => {
            instance.addChip(this.getFormattedDataForChip(item));
          })
        }
      }
    });
  }

  getIdsFromCheckedForTag() {
    const checkedForTag = document.querySelectorAll('.checked-for-tag');
    return [...checkedForTag].map(item => {
      return item.id.split('-')[1];
    });
  }

  getFormattedDataForChip(tag) {
    if (tag != null) {
      const formated = {
        tag: tag,
        image: ""
      }
      return (formated)
    } else {
      return null
    }
  }

  submitTags(){
    if(this.getIdsFromCheckedForTag().length === 0){
      Flash.error('Musíte vybrat některý produkt!');
      return
    }

    this.spinnerTarget.appendChild(createLoadingCircle());
    Rails.ajax({
      type: 'POST',
      url: this.submitUrlValue,
      data: this.getFormatedDataForSubmitTags(),
      success: res => {
        this.spinnerTarget.innerHTML = '';
        Flash.notice(res.message);
      },
      error: res => {
        this.spinnerTarget.innerHTML = '';
        Flash.error(res.message);
      }
    });
  }

  getFormatedDataForSubmitTags() {
    const instance = M.Chips.getInstance(this.tagListTarget);
    const dirtyData = instance.chipsData;
    const newData = dirtyData.filter( chip => chip.tag !== '' ).map( chip => chip.tag );
    const params = new URLSearchParams();
    newData.forEach(tag => {
      params.append('new[]', tag)
    });
    this.tagsBeforeChange.forEach(tag => {
      params.append('old[]', tag);
    })
    this.getIdsFromCheckedForTag().forEach(productId => {
      params.append('ids[]', productId);
    })
    return params.toString();
  }

}
