import {Controller} from 'stimulus'
import {getOffsetTop} from '../utils'

export default class extends Controller {
  static values = {
    offset: Number
  }

  scrollToTarget(event) {
    event.preventDefault();
    const scrollTarget = document.querySelector(this.element.attributes['href'].value);
    if (scrollTarget != null) {
      let count = getOffsetTop(scrollTarget) - document.documentElement.scrollTop;
      if (this.hasOffsetValue) count -= this.offsetValue;
      document.documentElement.scrollBy({
        top: count - 70,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  scrollToTop(event) {
    event.preventDefault();
    let count = -document.documentElement.scrollTop;
    if (this.hasOffsetValue) count -= this.offsetValue;
    document.documentElement.scrollBy({
      top: count,
      left: 0,
      behavior: 'smooth'
    });
  }
}
