import {Controller} from 'stimulus'
import Flash from '../flash'
import download from 'downloadjs'
import M from 'materialize-css'
import {getOffsetTop} from "../utils";

export default class extends Controller {
  static targets = ['submitRow']

  connect(){
    this.pushPinInstance = M.Pushpin.init(this.submitRowTarget, {
      top: 0,
      bottom: getOffsetTop(this.submitRowTarget),
      offset: window.innerHeight - this.submitRowTarget.getBoundingClientRect().height,
      onPositionChange: (status) => {
        if(status === 'pinned'){
          this.submitRowTarget.classList.add('container');
        }else{
          this.submitRowTarget.classList.remove('container');
        }
      }
    });
  }

  disconnect(){
    this.pushPinInstance.destroy();
  }

  async beforeFormSubmit(event){
    event.preventDefault();
    const form = event.currentTarget;
    const data = new FormData(form);
    const queryString = new URLSearchParams(data).toString();

    const response = await fetch(form.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: queryString
    })
    if(response.ok){
      this.downloadFile(await response.blob());
    }else if(response.status >= 500){
      Flash.error(I18n.t('internal_server_error'))
    }else{
      this.showModalErrors(await response.text());
    }
    form.querySelectorAll('[type="submit"]').forEach(submitElem => {
      submitElem.disabled = false;
    })
  }

  downloadFile(blob){
    download(blob, 'shop_listings.xlsx');
  }

  showModalErrors(modalHtml){
    const div = document.createElement('div');
    div.innerHTML = modalHtml;
    document.body.appendChild(div.firstChild);
  }
}
