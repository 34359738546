/*
  Reloads a Turbo frame. Only works with frames that have the src attribute set.
  A Turbo frame reloads after the src attribute has changed,
  but only if the loading policy is set to eager.
*/

export default frame => {
  const loadingPolicy = frame.loading
  frame.loading = 'eager'
  frame.src = frame.src
  frame.loading = loadingPolicy
}
