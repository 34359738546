import {Controller} from "stimulus";
import {createLoadingCircle} from "../spinner";

export default class extends Controller {
  static targets = ['autoSpin']
  static values = {
    target: String
  }

  connect(){
    if(this.hasAutoSpinTarget) {
      this.autoSpinTarget.appendChild(createLoadingCircle());
    }
  }

  startSpin(){
    let spinElem;
    if(this.hasTargetValue){
      spinElem = document.querySelector(this.targetValue);
    }else{
      spinElem = this.element;
    }

    if(spinElem != null){
      spinElem.appendChild(createLoadingCircle())
    }
  }

  stopSpin(){
    let spinElem;
    if(this.hasTargetValue){
      spinElem = document.querySelector(this.targetValue);
    }else{
      spinElem = this.element;
    }

    if(spinElem != null){
      spinElem.querySelectorAll('.preloader-wrapper').forEach(elem => elem.remove());
    }
  }
}
