import Rails from "@rails/ujs";
import Flash from "./flash";
import {Turbo} from "@hotwired/turbo-rails"
import reloadTurboFrame from './reload_turbo_frame'

export default class BestInStimulus {
  constructor({
                element,
                url,
                inputId,
                inputFieldName,
                inputFieldId,
                collection,
                inputValue,
                innerClass,
                placeHolder,
                wrapperClass,
                okButton,
                okButtonClass,
                reloadOnSuccess,
                turbo
              }) {
    this.element = element;
    this.url = url;
    this.inputId = inputId;
    this.inputFieldName = inputFieldName;
    this.inputFieldId = inputFieldId;
    this.collection = collection;
    this.inputValue = inputValue;
    this.innerClass = innerClass;
    this.placeHolder = placeHolder;
    this.wrapperClass = wrapperClass;
    this.okButton = okButton;
    this.okButtonClass = okButtonClass;
    this.formTag = null;
    this.inputFieldWrapper = null;
    this.isSelect = false;
    this.originalInputValue = this.inputValue;
    this.originalInputContent = this.element.innerHTML;
    this.reloadOnSuccess = reloadOnSuccess;
    this.turbo = turbo;
  }

  hasPlaceHolder() {
    return this.placeHolder != null && this.placeHolder !== '';
  }

  hasWrapperClass() {
    return this.wrapperClass != null && this.wrapperClass !== '';
  }

  hasOkButton() {
    return this.okButton != null && this.okButton !== '';
  }

  hasOkButtonClass() {
    return this.okButtonClass != null && this.okButtonClass !== '';
  }

  initCheckbox() {
    let newValue = null;
    this.collection.forEach(pair => {
      if (pair[0] !== this.inputValue) {
        newValue = pair[0];
      }
    });
    const data = new URLSearchParams({
      bip_input_id: this.inputId
    });
    data.set(this.inputFieldName, newValue);
    Rails.ajax({
      url: this.url,
      type: 'patch',
      data: data.toString(),
      success: data => {
        const frame = this.element.closest('turbo-frame');
        this.element.outerHTML = data.body.innerHTML;
        Flash.notice(I18n.t('best_in_stimulus.flash.notice'));
        if (this.reloadOnSuccess) {
          if (frame) {
            reloadTurboFrame(frame)
          } else {
            Turbo.visit(window.location);
          }
        }
      },
      error: (data, error, xhr) => {
        if (xhr.status < 500) {
          data.forEach(message => {
            Flash.error(message);
          })
        } else {
          Flash.error(I18n.t('best_in_stimulus.flash.error'));
        }
      }
    })
  }

  initHelperForm() {
    this.formTag = document.createElement('form');
    this.formTag.setAttribute('data-controller', 'materialize');
    this.formTag.setAttribute('data-action', 'ajax:success->remote#replace ajax:error->remote#error');
    this.formTag.setAttribute('data-remote', `${!this.turbo}`);
    if (!this.turbo) this.formTag.setAttribute('data-turbo-frame', '_top');
    this.formTag.setAttribute('action', this.url);
    this.formTag.setAttribute('accept-charset', 'UTF-8');
    this.formTag.setAttribute('method', 'post');
    this.formTag.innerHTML = `
      <input type="hidden" name="_method" value="patch">
    `
    if (!this.turbo) {
      this.formTag.innerHTML += `
          <input type="hidden" name="bip_input_id" value="${this.inputId}">
      `
    }
    this.inputFieldWrapper = this.formTag;
    if (this.reloadOnSuccess) {
      this.formTag.addEventListener('ajax:success', () => {
        const frame = this.element.closest('turbo-frame');
        if (frame) {
          reloadTurboFrame(frame)
        } else {
          Turbo.visit(window.location);
        }
      });
    }
    this.formTag.addEventListener('ajax:success', () => {
      Flash.notice(I18n.t('best_in_stimulus.flash.notice'));
    })
    this.formTag.addEventListener('ajax:error', event => {
      const errorMessage = event.detail[0].join("\n")
      Flash.error(errorMessage)

      this.element.innerHTML = this.originalInputContent;
      const parentCopy = this.element.cloneNode(true);
      this.element.outerHTML = parentCopy.outerHTML;
    })

    return this.formTag;
  }

  createSelectOption() {
    this.isSelect = true;
    this.inputFieldWrapper.innerHTML += `
          <select name="${this.inputFieldName}" tabindex="-1" id="${this.inputFieldId}" class="${this.innerClass}">
          ${this.hasPlaceHolder() && this.inputValue === '' ? `<option>${this.placeHolder}</option>` : ''}
          ${this.collection.map(option => {
      return `<option value="${option[0]}"${option[0].toString() === this.inputValue ? ' selected="selected"' : ''}>${option[1]}</option>`
    }).join('')}
          </select>
        `;
  }

  createInputFieldOption() {
    this.inputFieldWrapper.innerHTML += `
          <input id="${this.inputFieldId}" type='text' name="${this.inputFieldName}" value="${this.inputValue}" class="${this.innerClass}" placeholder="${this.placeHolder}">
        `
  }

  createNumberFieldOption() {
    this.inputFieldWrapper.innerHTML += `
          <input id="${this.inputFieldId}" type='number' name="${this.inputFieldName}" value="${this.inputValue}" class="${this.innerClass}" placeholder="${this.placeHolder}">
        `
  }

  createTextAreaOption() {
    this.inputFieldWrapper.innerHTML += `
          <textarea id="${this.inputFieldId}" name="${this.inputFieldName}" class="${this.innerClass}" placeholder="${this.placeHolder}">${this.inputValue}</textarea>
        `
  }

  initFormSubmit() {
    const inputField = this.inputFieldWrapper.querySelector('input:not([type="hidden"]),select,textarea');
    if (this.hasOkButton()) {
      this.formTag.innerHTML += `
        <button type="submit"${this.hasOkButtonClass() ? ` class="${this.okButtonClass}"` : ''}>${this.okButton}</button>
      `;
      if (!this.isSelect) {
        this.formTag.addEventListener('focusout', () => {
          setTimeout(() => {
            this.element.innerHTML = this.originalInputContent;
            const parentCopy = this.element.cloneNode(true);
            this.element.outerHTML = parentCopy.outerHTML;
          }, 1000);
        });
      }
    } else {
      if (this.isSelect) {
        this.formTag.addEventListener('change', () => {
          Rails.fire(this.formTag, 'submit');
        });

      } else {
        this.formTag.addEventListener('focusout', () => {
          if (inputField.value !== this.originalInputValue) {
            Rails.fire(this.formTag, 'submit');
          } else {
            this.element.innerHTML = this.originalInputContent;
            const parentCopy = this.element.cloneNode(true);
            this.element.outerHTML = parentCopy.outerHTML;
          }
        });
      }
    }
  }

  addCustomHtmlAttrs(htmlAttrs) {
    const inputField = this.inputFieldWrapper.querySelector('input:not([type="hidden"]),select,textarea');
    Object.entries(htmlAttrs).forEach(entry => {
      inputField.setAttribute(entry[0], entry[1]);
    })
  }

  focus() {
    let inputField = this.inputFieldWrapper.querySelector('input:not([type="hidden"]),select,textarea');
    if (!this.isSelect) {
      inputField.focus()
      if (inputField.setSelectionRange) {
        inputField.setSelectionRange(inputField.value.length, inputField.value.length);
      } else {
        inputField.select();
        inputField.selectionStart = inputField.selectionEnd = inputField.value.length;
      }
    }
  }
}
