import {Controller} from 'stimulus'
import Flash from '../flash'
import AutoComplete from '@tarekraafat/autocomplete.js'
import {getOffsetLeft, getOffsetTop} from "../utils"
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = {
    productId: String,
    searchUrl: String
  }

  static targets = ['autocomplete', 'productsTable']

  connect() {
    new AutoComplete({
      data: {
        src: async () => {
          const query = this.autocompleteTarget.value;
          const source = await fetch(`${this.searchUrlValue}?term=${encodeURIComponent(query)}`);
          return await source.json();
        },
        key: ['title']
      },
      selector: () => {
        return this.autocompleteTarget;
      },
      threshold: 3,
      placeholder: this.autocompleteTarget.placeholder,
      resultsList: {
        container: (source) => {
          source.style.position = 'absolute';
          source.style.top = `${getOffsetTop(this.element) + this.element.getBoundingClientRect().height}px`;
          source.style.left = `${getOffsetLeft(this.element)}px`;
          source.style.width = `${this.element.getBoundingClientRect().width}px`;
          source.removeAttribute('id');
        },
        destination: () => {
          return document.body;
        },
        className: 'collection autocomplete',
        element: 'div'
      },
      resultItem: {
        content: (data, source) => {
          source.innerHTML = data.match;
          source.removeAttribute('id');
          source.href = '#!';
          source.dataset.turbo = 'false';
        },
        element: "a",
        className: 'collection-item'
      },
      onSelection: (feedback) => {
        this.addRelatedProduct(feedback.selection.value);
      }
    });
  }

  updateRelation(relatedProductId, data) {
    Rails.ajax({
      url: `/products/${this.productIdValue}/related_products/${relatedProductId}`,
      type: 'PATCH',
      data: data.toString(),
      success: () => {
        Flash.notice('Aktualizováno');
      },
      error: () => {
        Flash.error('Došlo k chybě');
      }
    })
  }

  updatePreferentiallySetting(event) {
    const checkbox = event.currentTarget;
    const relatedProductId = checkbox.dataset.productId;
    const data = new URLSearchParams({
      show_preferentially: checkbox.checked
    })
    this.updateRelation(relatedProductId, data)
  }

  updateRelationType(event) {
    const select = event.currentTarget;
    const relatedProductId = select.dataset.productId;
    const data = new URLSearchParams({
      relation_type: select.value
    })
    this.updateRelation(relatedProductId, data)
  }

  addRelatedProduct(item) {
    const relatedProductId = item.id;

    this.autocompleteTarget.value = '';
    const data = new URLSearchParams({
      'related_product_ids[]': relatedProductId
    })
    Rails.ajax({
      url: `/products/${this.productIdValue}/related_products`,
      type: 'POST',
      data: data.toString(),
      success: () => {
        const row = document.createElement('tr');
        row.dataset.controller = 'remote';
        row.innerHTML = this.createRow(this.productIdValue, relatedProductId, item.title)
        this.productsTableTarget.querySelector('tbody').appendChild(row);
        M.AutoInit(row)
      },
      error: () => {
        Flash.error('Přidání souvisejícího produktu se nezdařilo');
      }
    });
  };

  createRow(productId, relatedProductId, title) {
    return `
    <td>
      <a href='/products/${relatedProductId}'>
        ${title}
      </a>
    </td>
    <td>
      <div class="input-field col s12">
        <select name="relation_type" id="relation_type" tabindex="-1" data-product-id="${relatedProductId}" data-action="change->related-products#updateRelationType">
        <option value="related">Související</option>
        <option value="similar">Podobné</option></select>
      </div>
    </td>
    <td>
      <label>
        <input type='checkbox' data-product-id="${relatedProductId}" data-action="change->related-products#updatePreferentiallySetting">
        <span></span>
      </label>
    </td>
    <td>
      <a class="btn-small red remove-relation" rel='nofollow' data-method='delete' href='/products/${productId}/related_products/${relatedProductId}' data-remote="true" data-action="ajax:success->remote#remove">
        <i class='material-icons'>
          highlight_off
        </i>
      </a>
    </td>
  `
  }
}
