import { Controller } from 'stimulus'
import {getOffsetTop} from "../utils";
import M from "materialize-css";

export default class extends Controller {
  static values = {
    offset: Number,
    triggerPosition: String
  }

  connect(){
    let top = getOffsetTop(this.element);
    if(this.triggerPositionValue === 'bottom'){
      top += this.element.getBoundingClientRect().height
    }
    M.Pushpin.init(this.element, {
      top: top,
      offset: this.offsetValue
    });
  }
}
