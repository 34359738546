import {Controller} from 'stimulus';
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['searchInput']

  search() {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      Rails.fire(this.element, 'submit');
    }, 300);
  }

  clear(){
    this.searchInputTarget.value = '';
  }
}
