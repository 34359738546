import { Controller } from 'stimulus'
import iro from '@jaames/iro'

export default class extends Controller{
  static targets = ['colorPicker', 'inputField', 'colorBox']

  connect(){
    this.colorPickerInstance = new iro.ColorPicker(this.colorPickerTarget);
    if(this.inputFieldTarget.value !== '') {
      this.updateColor();
    }
    this.element.style.lineHeight = `${this.colorPickerInstance.props.height}px`;
    this.colorPickerInstance.on('color:change', color => {
      this.inputFieldTarget.value = color.hexString;
      this.colorBoxTarget.style.backgroundColor = color.hexString;
      M.updateTextFields();
    });
  }

  disconnect(){
    this.colorPickerTarget.innerHTML = '';
  }

  updateColor(){
    try {
      this.colorPickerInstance.color.hexString = this.inputFieldTarget.value;
      this.colorBoxTarget.style.backgroundColor = this.inputFieldTarget.value;
    }catch (e) {
      console.error(e);
    }
  }
}
