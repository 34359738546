import { Controller } from 'stimulus'
import Selectize from "../selectize";

export default class extends Controller {
  static values = {
    url: String,
    searchAttribute: String,
    displayKey: String,
    highlight: Boolean,
    maxResults: Number,
    placeHolder: String,
    threshold: Number,
    searchEngine: String,
    diacritics: Boolean,
    inline: Boolean,
    displayAttribute: String,
    valueAttribute: String
  }


  connect() {
    const searchAttribute = this.hasSearchAttributeValue ? this.searchAttributeValue : undefined;
    const displayKey = this.hasDisplayKeyValue ? [this.displayKeyValue] : undefined;
    const highlight = this.hasHighlightValue ? this.highlightValue : undefined;
    const maxResults = this.hasMaxResultsValue ? this.maxResultsValue : undefined;
    const placeHolder = this.hasPlaceHolderValue ? this.placeHolderValue : undefined;
    const threshold = this.hasThresholdValue ? this.thresholdValue : undefined;
    const searchEngine = this.hasSearchEngineValue ? this.searchEngineValue : undefined;
    const diacritics = this.hasDiacriticsValue ? this.diacriticsValue : undefined;
    const inline = this.hasInlineValue ? this.inlineValue : undefined;
    this.selectize = new Selectize(this.element, {
      dataUrl: this.urlValue,
      searchAttribute: searchAttribute,
      displayKey: displayKey,
      highlight: highlight,
      maxResults: maxResults,
      placeHolder: placeHolder,
      threshold: threshold,
      searchEngine: searchEngine,
      diacritics: diacritics,
      inline: inline,
      displayAttribute: this.displayAttributeValue,
      valueAttribute: this.valueAttributeValue
    })
    M.updateTextFields()
  }

  disconnect(){
    this.selectize.destroy();
  }


}
