import { Controller } from 'stimulus'
import { mattInit, mattDestroy } from "../matt_init";

export default class extends Controller {
  connect() {
    mattInit(this.element);
  }

  disconnect(){
    mattDestroy(this.element);
  }
}
