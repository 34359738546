import '../stylesheets/application.scss';
// eslint-disable-next-line no-undef
import "@hotwired/turbo-rails"
import * as ActiveStorage from '@rails/activestorage'

require("@rails/ujs").start();
require.context('../images', true);

import "javascript/controllers"

import {mattDestroy, mattInit} from "../javascript/matt_init";
import { createLoadingCircle } from "../javascript/spinner";

ActiveStorage.start();
global.I18n = require('i18n-js');

document.addEventListener('turbo:load', () => {
  mattInit()

  // Get forms except those created by the button_to helper
  const forms = document.querySelectorAll('form:not(.button_to):not([data-remote=true])')
  const submits = [...forms].flatMap(form => [...form.querySelectorAll('button[type="submit"]')])
  submits.forEach(submit => {
    submit.closest('form').addEventListener('submit', e => {
      const target = e.submitter;
      target.style.display = 'none';
      target.closest('div').appendChild(createLoadingCircle());
    });
  });
});

document.addEventListener('turbo:before-cache', () => {
  mattDestroy(document.body);
});

