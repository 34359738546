import { Controller } from 'stimulus'
import M from 'materialize-css'

export default class extends Controller {
  connect() {
    const modal = M.Modal.init(this.element,{
      onCloseEnd: () => this.close()
    });

    modal.open();
  }

  close() {
    const mInstance = M.Modal.getInstance(this.element);
    if(mInstance != null) {
      mInstance.close();
      mInstance.destroy();
    }
    const sideNav = document.querySelector('.sidenav');
    if (sideNav != null) {
      const mSideNav = M.Sidenav.getInstance(sideNav);
      if (mSideNav != null) {
        mSideNav.destroy();
        const sideClone = sideNav.cloneNode(true);
        sideNav.remove();
        document.querySelector('body').appendChild(sideClone);
      }
    }
    this.element.remove();
  }
}
