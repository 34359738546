import {Controller} from 'stimulus'
import Flash from 'flash'

export default class extends Controller {
  notice(event) {
    Flash.notice(event.detail[0].notice)
  }

  error(event) {
    Flash.error(event.detail[0].error)
  }

  async turboResponse(event){
    const formResult = event.detail.formSubmission.result;
    const response = await event.detail.fetchResponse.response.json();
    if(formResult.success){
      Flash.notice(response.notice)
    }else{
      Flash.error(response.error)
    }
  }
}
